import React from 'react';
import styled from 'styled-components';
import SectionHeader from '../components/SectionHeader';
import Layout from '../components/Layout';

const TkankSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 50px;
`;

const Container = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
`;

const Thanks = () => (
  <Layout>
    <TkankSection>
      <Container>
        <SectionHeader
          title="Dziękujemy"
          subtitle="Po przeczytaniu wiadomości, skontakujemy się z tobą w najbliższej wolnej chwili."
        />
      </Container>
    </TkankSection>
  </Layout>
);

export default Thanks;
